import React from 'react';
import Navbar from './Navbar';

const HomePage = () => {
    return (
        <>
            <Navbar />
        </>
    );
};

export default HomePage;
