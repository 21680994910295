import React, { useState } from 'react';
import '../styles/Navbar.css';
import { ReactComponent as MenuIcon } from '../assets/menu-icon.svg';
import { ReactComponent as CloseIcon } from '../assets/menu-icon-close.svg';
import { Drawer, Button } from 'antd';

const Navbar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="navbar">
            <div className="menu-icon-container">
                <MenuIcon onClick={toggleMenu} className="menu-icon" />
            </div>
            <div className="navbar-content">
            </div>
            <div className="button-container">
                <button className="book-appointment-btn">BOOK</button>
            </div>

            <Drawer
                className="custom-drawer"
                title={<Button type="text" onClick={toggleMenu} style={{ color: 'white', border: 'none' }}><CloseIcon /></Button>} // Updated this line
                placement="left"
                closable={false}
                onClose={toggleMenu}
                visible={isMenuOpen}
                key="left"
                drawerStyle={{ backgroundColor: '#111', color: 'white' }}
                headerStyle={{ borderBottom: 'none' }}
            >
                <p style={drawerItemStyle}><Button type="link" onClick={() => console.log('Navigate to Home')} style={drawerButtonStyle}>HOME</Button></p>
                <p style={drawerItemStyle}><Button type="link" onClick={() => console.log('Navigate to Services')} style={drawerButtonStyle}>SERVICES</Button></p>
                <p style={drawerItemStyle}><Button type="link" onClick={() => console.log('Navigate to Contact')} style={drawerButtonStyle}>CONTACT</Button></p>
            </Drawer>
        </nav>
    );
};

const drawerItemStyle = {
    margin: 0,
    fontWeight: 'bold',
    textTransform: 'uppercase'
};

const drawerButtonStyle = {
    color: 'white',
    padding: 0
};

export default Navbar;


