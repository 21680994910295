import React, { useState, useEffect, useRef } from 'react';
import '../styles/ComingSoon.css';
import '../styles/styles.css';
import logo from '../assets/BOOF.svg'
import MatrixBackground from './MatrixBackground';

const ComingSoon = () => {
  const [currentTime, setCurrentTime] = useState('');
  const [countdown, setCountdown] = useState('');
  const [solanaAddress, setSolanaAddress] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [onlineUsers, setOnlineUsers] = useState(0);
  const ws = useRef(null);

  useEffect(() => {
    const targetDate = new Date('October 31, 2024 00:00:00').getTime();
    
    const formatTime = (date) => {
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const year = date.getFullYear().toString().substr(-2);
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const ampm = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12;
      const timezone = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2];
      return `${month.toString().padStart(2, '0')}.${day.toString().padStart(2, '0')}.${year} ${formattedHours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm} ${timezone}`;
    };

    const calculateCountdown = (distance) => {
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hoursCountdown = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutesCountdown = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const secondsCountdown = Math.floor((distance % (1000 * 60)) / 1000);
      const millisecondsCountdown = Math.floor((distance % 1000) / 10);
      return `${days.toString().padStart(2, '0')} : ${hoursCountdown.toString().padStart(2, '0')} : ${minutesCountdown.toString().padStart(2, '0')} : ${secondsCountdown.toString().padStart(2, '0')} : ${millisecondsCountdown.toString().padStart(2, '0')}`;
    };

    const timer = setInterval(() => {
      const now = new Date();
      setCurrentTime(formatTime(now));

      const distance = targetDate - now.getTime();
      if (distance < 0) {
        clearInterval(timer);
        setCountdown("EXPIRED");
      } else {
        setCountdown(calculateCountdown(distance));
      }
    }, 10);

    return () => clearInterval(timer);
  }, []);

  const isValidSolanaAddress = (address) => {
    return address.length === 44 && address.startsWith('3');
  };

  const handleAddressChange = (e) => {
    const address = e.target.value;
    setSolanaAddress(address);
    if (address && !isValidSolanaAddress(address)) {
      setErrorMessage('Invalid Solana address');
    } else {
      setErrorMessage('');
    }
  };

  useEffect(() => {
    setIsButtonDisabled(!isValidSolanaAddress(solanaAddress));
  }, [solanaAddress]);

  useEffect(() => {
    // Connect to WebSocket server
    ws.current = new WebSocket('ws://localhost:8080');

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.type === 'userCount') {
        setOnlineUsers(message.count);
      }
    };

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  const handleAddToWhitelist = () => {
    // Add your whitelist logic here
  };

  return (
    <>
      <MatrixBackground />
      <div className="coming-soon-container" style={{ position: 'relative', zIndex: 1 }}>
        <img src={logo} alt="Logo" className="logo" />
        <p className="current-time">{currentTime}</p>
        <p className="wen-boof">WEN BOOF.</p>
        <p className="countdown">{countdown}</p>
        <div>
          <input
            type="text"
            value={solanaAddress}
            onChange={handleAddressChange}
            placeholder="Enter Solana Address"
            className="solana-input"
          />
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          <button 
            disabled={isButtonDisabled} 
            className="add-whitelist-btn"
            onClick={handleAddToWhitelist}
          >
            Add to Whitelist
          </button>
        </div>
        <div className="online-users vibrate">
          <span className="live-dot"></span>
          {onlineUsers} BOOFER{onlineUsers !== 1 && 's'} online!
        </div>
      </div>
    </>
  );
};

export default ComingSoon;